<template>
  <div class="flex-column">
    <span v-if="hasTitle" class="dysfunctions-list__title">
      {{ $t('dashboard.diagnostic.dysfunctions') }}
    </span>
    <ul v-if="isDiagnosticV1" class="dysfunctions-list flex-column">
      <dysfunction
        v-for="dysfunction in skinDysfunctions"
        :key="dysfunction.name"
        :skin-dysfunction="dysfunction"
        :is-questionnaire-diagnostic="isQuestionnaireDiagnostic"
      ></dysfunction>
    </ul>
    <ul v-if="isDiagnosticV2" class="dysfunctions-list flex-column">
      <dysfunctionV2
        v-for="dysfunction in skinDysfunctionsV2"
        :key="dysfunction.name"
        :skin-dysfunction="dysfunction"
        :is-questionnaire-diagnostic="isQuestionnaireDiagnostic"
      ></dysfunctionV2>
    </ul>
  </div>
</template>

<script>
import { pick, omit, get } from 'lodash';

import Dysfunction from '@/modules/dashboard/components/diagnostic-tab/Dysfunction';
import DysfunctionV2 from '@/modules/dashboard/components/diagnostic-tab/DysfunctionV2';

import {
  SELECT_DATA_FIELDS,
  COLORS_TO_ACTIVES_COMPATIBILITY,
  ACTIVES
} from '@/modules/dashboard/api/constants';


const QUESTIONNAIRE_DIAGNOSTIC_FIELDS_TO_OMIT = ['currentPhototype', 'normalPhototype', 'skinType'];

const PHOTO_DIAGNOSTIC_FIELDS_TO_OMIT = [
  ...SELECT_DATA_FIELDS,
  'ageGroup',
  'ethnicity',
  'skinReactsToProducts'
];

const PHOTO_DIAGNOSTIC_FIELDS_TO_OMIT_V2 = [
  'ageGroup',
  'ethnicity',
  'skinReactsToProducts'
];

const SECTIONS_DYSFUNCTIONS_FIELDS = {
  [ACTIVES.SKIN_APPEARANCE]: ['hasAcneScars', 'wrinklesDepth'],
  [ACTIVES.SKIN_REDNESS]: ['hasRosacea1', 'hasCouperose'],
  [ACTIVES.SKIN_DRYNESS]: ['dryness', 'dehydration'],
  [ACTIVES.PIMPLES]: ['hasAcneVulgaris', 'hasAcneRosacea', 'hasAcneNodular'],
  [ACTIVES.SKIN_PIGMENTATION]: ['comesFromPIH', 'comesFromAgeSpotsMelasma']
};

const SECTIONS_DYSFUNCTIONS_FIELDS_V2 = {
  [ACTIVES.OXIDATIVE_STRESS]: ['complexion', 'tiredness', 'sunDamage'],
  [ACTIVES.SKIN_APPEARANCE]: ['wrinkles', 'fineLines', 'elasticitySagging', 'volume'],
  [ACTIVES.SKIN_REDNESS]: ['rednessPresent', 'couperosePresent', 'isRosacea', 'isSunburn', 'isContactDermatitis', 'isEczema', 'isPsoriasis', 'isInfections', 'isAcne', 'isAllergicReaction'],
  [ACTIVES.SKIN_DRYNESS]: ['observedDryness', 'observedDehydration', 'predictiveFactorsDryness', 'predictiveFactorsDehydration'],
  [ACTIVES.SKIN_OILINESS]: ['oiliness', 'pores'],
  [ACTIVES.SKIN_TEXTURE]: ['roughBumpySkin', 'dullSkin', 'unevenSkinTexture', 'roughness', 'scarring'],
  [ACTIVES.PIMPLES]: ['comedones', 'pustules', 'papules', 'nodules', 'cysts'],
  [ACTIVES.SKIN_PIGMENTATION]: ['melasma', 'postInflammatoryHyperpigmentation', 'ageSunSpots', 'freckles', 'moles', 'skinTone', 'predictiveFactorsHyperpigmentation']
};


// eslint-disable-next-line no-unused-vars
function isNullOrEmpty(obj) {
  // eslint-disable-next-line no-alert
  // alert(JSON.stringify(obj, null, 4));
  return obj == null || (typeof obj === 'object' && Object.keys(obj).length === 0);
}


function transformObject(inputObject) {
  const result = [];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in,no-unused-vars
  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      result.push({
        name: key,
        value: inputObject[key].description
      });
    }
  }

  return result;
}


export default {
  name: 'SkinDysfunctionsList',
  components: { Dysfunction, DysfunctionV2 },
  props: {
    diagnostic: {
      type: Object,
      default: () => ({})
    },
    isQuestionnaireDiagnostic: {
      type: Boolean,
      default: false
    },
    hasTitle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    fieldsToOmit() {
      return this.isQuestionnaireDiagnostic
        ? QUESTIONNAIRE_DIAGNOSTIC_FIELDS_TO_OMIT
        : PHOTO_DIAGNOSTIC_FIELDS_TO_OMIT;
    },
    isDiagnosticV2() {
      let retVAL = false;
      if (!isNullOrEmpty(this.diagnostic.photoSkinCharacteristics)) retVAL = true;
      return retVAL;
    },
    isDiagnosticV1() {
      let retVAL = false;
      if (isNullOrEmpty(this.diagnostic.photoSkinCharacteristics)) retVAL = true;
      return retVAL;
    },
    skinDysfunctions() {
      const { questionnaireSkinCharacteristicsDto, photoSkinCharacteristicsDto } = this.diagnostic;

      const skinCharacteristics = this.isQuestionnaireDiagnostic
        ? questionnaireSkinCharacteristicsDto
        : photoSkinCharacteristicsDto;

      return this.getSkinDysfunctions(skinCharacteristics);
    },
    skinDysfunctionsV2() {
      const { questionnaireSkinCharacteristicsDto, photoSkinCharacteristics } = this.diagnostic;

      const skinCharacteristics = this.isQuestionnaireDiagnostic
        ? questionnaireSkinCharacteristicsDto
        : photoSkinCharacteristics;

      return this.getSkinDysfunctionsV2(skinCharacteristics);
    }
  },
  methods: {
    isNullOrEmpty(obj) {
      return obj == null || (typeof obj === 'object' && Object.keys(obj).length === 0);
    },
    getSkinDysfunctionsV2(skinCharacteristics) {
      const dysfunctionsFields = omit(skinCharacteristics, PHOTO_DIAGNOSTIC_FIELDS_TO_OMIT_V2);
      if (this.isQuestionnaireDiagnostic) {
        return dysfunctionsFields;
      }


      const colorOrder = ['yellow', 'pink', 'red', 'blue', 'orange', 'grey', 'green', 'brown'];

      const orderedColors = colorOrder.reduce((acc, color) => {
        if (dysfunctionsFields[color]) {
          acc[color] = dysfunctionsFields[color]; // Only add the color if it exists in the data
        }
        return acc;
      }, {});


      return Object.entries(orderedColors).reduce(
        (skinDysfunctions, [dysfunctionColor, dysfunctionScore]) => {
          const score = get(dysfunctionScore, 'dysfunctionScore');
          const dysfunctionSection = COLORS_TO_ACTIVES_COMPATIBILITY[dysfunctionColor];


          if (!dysfunctionSection) {
            return skinDysfunctions;
          }
          const sectionDysfunctionsFieldsToOmit = SECTIONS_DYSFUNCTIONS_FIELDS_V2[dysfunctionSection];


          const sectionDysfunctionsFields = pick(
            dysfunctionScore,
            sectionDysfunctionsFieldsToOmit
          );

          const items = transformObject(sectionDysfunctionsFields);

          return {
            ...skinDysfunctions,
            [dysfunctionSection]: {
              name: dysfunctionSection,
              score,
              items
            }
          };
        },
        {}
      );
    },
    getSkinDysfunctions(skinCharacteristics) {
      const dysfunctionsFields = omit(skinCharacteristics, this.fieldsToOmit);

      if (this.isQuestionnaireDiagnostic) {
        return dysfunctionsFields;
      }

      return Object.entries(dysfunctionsFields).reduce(
        (skinDysfunctions, [dysfunctionColor, score]) => {
          const dysfunctionSection = COLORS_TO_ACTIVES_COMPATIBILITY[dysfunctionColor];

          if (!dysfunctionSection) {
            return skinDysfunctions;
          }

          const sectionDysfunctionsFieldsToOmit = SECTIONS_DYSFUNCTIONS_FIELDS[dysfunctionSection];

          const sectionDysfunctionsFields = pick(
            skinCharacteristics,
            sectionDysfunctionsFieldsToOmit
          );

          const items = Object.entries(sectionDysfunctionsFields).map(([key, value]) => ({
            name: key,
            value
          }));


          return {
            ...skinDysfunctions,
            [dysfunctionSection]: {
              name: dysfunctionSection,
              score,
              items
            }
          };
        },
        {}
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.dysfunctions-list {
  padding: 0;
  margin-bottom: 15px;

  &__title {
    padding-left: 163px;
    font-size: 15px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .dysfunctions-list {
    &__title {
      margin: 0 auto;
      padding: 0 46px 0 0;
    }
  }
}

@media (max-width: 369px) {
  .dysfunctions-list {
    &__title {
      margin-left: 123px;
    }
  }
}
</style>
